const messages = {
  shared: {
    covid: {
      app_unknown:
        "Die Hygieneregeln für diese Veranstaltung stehen noch nicht fest. Du solltest kurz vor der Veranstaltung noch mal nachschauen.",
      app_proof:
        "Für den Besuch dieser Veranstaltung musst du einen COVID-19 Nachweis mitbringen.",
      app_noproof:
        "Für den Besuch dieser Veranstaltung brauchst du keine besonderen COVID-19 Nachweise mitbringen.",
    },
  },
};

export { messages };
